<template>
    <div class="right_button_box">

        <div class="btns">
<!--            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}">-->
<!--                Deposit-->
<!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}">-->
<!--                Withdraw-->
<!--            </router-link>-->
            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                Q&A
            </router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/notice', query: {t: new Date().getTime()}}">
                Notice
            </router-link>
<!--            <router-link tag="button" class="btn-1" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">-->
<!--                Attendance-->
<!--            </router-link>-->
            <router-link tag="button" class="btn-1" :to="{path: '/message', query: {t: new Date().getTime()}}">
                Message
            </router-link>
<!--            <router-link tag="button" class="btn-1" :to="{path: '/event', query: {t: new Date().getTime()}}">-->
<!--                Promo-->
<!--            </router-link>-->
            <router-link tag="button" class="btn-1" :to="{path: '/sports_result', query: {t: new Date().getTime()}}">
                Result
            </router-link>

<!--            <router-link tag="button" class="btn-1" :to="{path: '/user_info', query: {t: new Date().getTime()}}">-->
<!--                회원정보-->
<!--            </router-link>-->
            <!--            <router-link tag="button" class="btn-1" :to="{path: '/board', query: {t: new Date().getTime()}}">-->
            <!--                게시판-->
            <!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" to="" @click.native="payback">-->
<!--                본인페이백-->
<!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" to="" @click.native="payback4referrer">-->
<!--                지인페이백-->
<!--            </router-link>-->
        </div>

<!--        <div class="btns">-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}">-->
<!--                <img src="../assets/images/icon/cenha/icon-recharge.png"/> 입금-->
<!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}">-->
<!--                <img src="../assets/images/icon/cenha/icon-exchange.png"/> 출금-->
<!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">-->
<!--                <img src="../assets/images/icon/cenha/icon-qustion.png"/> 고객센터-->
<!--            </router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/message', query: {t: new Date().getTime()}}">-->
<!--                <img src="../assets/images/icon/cenha/icon-message.png"/> 쪽지함-->
<!--            </router-link>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {coffetimevent, logout, owlevent, payback, payback4referrer, run369} from "../network/userRequest";

    export default {
        name: "RightButtonsComp",
        methods:{
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/login")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            payback() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                payback().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '페이백 지급 성공',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            payback4referrer() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                payback4referrer().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '지인페이백 지급 성공',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            coffetimevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                coffetimevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '커피값 이벤트 ' + res.data.msg + 'P 지급 완료',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            owlevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                owlevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '야식 이벤트란 규정 필수 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            run369() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                run369().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '[중요]돌발이벤트 규정 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
        }
    }
</script>

<style scoped>
    .right_button_box{
        width: 100%;
        padding: 8px 1px;
    }
    .right_button_box .btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px 0;
        --border-bottom: 1px solid #d9cdb5;
    }
    .right_button_box .btns .btn-1 {
        width: 24.6%;
        height: 34px;
        background-color: var(--leftBarBtnBg);
        color: #353539;
        margin: 1px 0;
        border-radius: 3px;
        font-weight: bold;
    }

    .right_button_box .btns .btn-1 img {
        width: 20px;
        vertical-align: middle;
    }
    @media screen and (max-width: 1024px) {
        .right_button_box{
            --display: none!important;
        }
    }
</style>